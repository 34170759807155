import { Controller } from '@hotwired/stimulus';
const controller = class extends Controller {
    constructor(context) {
        super(context);
        this.__stimulusLazyController = true;
    }
    initialize() {
        if (this.application.controllers.find((controller) => {
            return controller.identifier === this.identifier && controller.__stimulusLazyController;
        })) {
            return;
        }
        import('/home/bred/bred/assets/controllers/subscription-controller.js').then((controller) => {
            this.application.register(this.identifier, controller.default);
        });
    }
};
export { controller as default };